import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { AuthService } from '@shared/services/auth.service';

import { Router } from '@angular/router';
import {
  BroadcastChannelService,
  MessageTypes,
} from '@shared/services/broadcast-channel.service';
import {
  Login,
  Logout,
  RefreshAuth,
  SetUserActiveDivision,
  SetUserDivisions,
  SyncState,
} from './auth.actions';
import { RouteNames } from '../../route-names';
import { AuthStateModel, Role } from '../../types/auth';

const initialState = () => {
  return {
    token: null,
    refreshToken: null,
    user: null,
    divisions: [],
    authorities: [],
    roleName: null,
  };
};

const authStateBroadcastChannel = new BroadcastChannel('ngxs-sync');

@State<AuthStateModel>({
  name: 'auth',
  defaults: initialState(),
})
@Injectable()
export class AuthState {
  constructor(
    private readonly auth: AuthService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly broadcastChannel: BroadcastChannelService,
  ) {
    authStateBroadcastChannel.addEventListener(
      'message',
      (event: MessageEvent<AuthStateModel>) => {
        this.store.dispatch(new SyncState(event.data));
      },
    );
  }

  @Action(SyncState)
  syncState(ctx: StateContext<AuthStateModel>, action: SyncState) {
    ctx.setState(action.state);
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.auth.login(action.credentials).pipe(
      tap(data => {
        ctx.patchState({
          token: data.jwtToken,
          refreshToken: data.refreshToken,
          user: data.user,
          authorities: [...new Set(data.authorities)],
          roleName: data.roleName,
        });

        authStateBroadcastChannel.postMessage(ctx.getState());
        this.router.navigate([RouteNames.KANBAN_PAGE]);
        this.broadcastChannel.postMessage({
          type: MessageTypes.NAVIGATE_KANBAN,
        });
      }),
    );
  }

  @Action(RefreshAuth)
  refreshAuth(ctx: StateContext<AuthStateModel>, action: RefreshAuth) {
    const data = action.auth;
    ctx.patchState({
      token: data.jwtToken,
      user: data.user,
      refreshToken: data.refreshToken,
      authorities: [...new Set(data.authorities)],
      roleName: data.roleName,
    });
    authStateBroadcastChannel.postMessage(ctx.getState());
  }

  @Action(SetUserDivisions)
  setUserDivisions(ctx: StateContext<AuthStateModel>) {
    return this.auth.getUserDivisions().pipe(
      tap(divisions => {
        ctx.patchState({
          divisions,
        });
        authStateBroadcastChannel.postMessage(ctx.getState());
      }),
    );
  }

  @Action(SetUserActiveDivision)
  setUserActiveDivision(
    ctx: StateContext<AuthStateModel>,
    action: SetUserActiveDivision,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      user: state.user
        ? {
            ...state.user,
            activeDivisionId: action.id,
          }
        : null,
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    ctx.patchState(initialState());
    this.router.navigate([RouteNames.LOGIN_PAGE]);
    this.broadcastChannel.postMessage({
      type: MessageTypes.NAVIGATE_LOGIN,
    });
  }

  @Selector()
  static getUser(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  static getToken(state: AuthStateModel) {
    return state.token;
  }

  @Selector()
  static getRefreshToken(state: AuthStateModel) {
    return state.refreshToken;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel) {
    return !!state.user?.email;
  }

  @Selector()
  static getUserActiveDivision(state: AuthStateModel) {
    return state.user?.activeDivisionId;
  }

  @Selector()
  static getUserDivisions(state: AuthStateModel) {
    return state.divisions;
  }

  @Selector()
  static getUserAuthorities(state: AuthStateModel) {
    return state.authorities;
  }

  @Selector()
  static getUserRole(state: AuthStateModel) {
    return state.roleName;
  }

  static hasRole(role: Role) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      return state.roleName === role;
    });
  }

  static hasAuthority(authority: string) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      return state.authorities.includes(authority);
    });
  }
}
