export enum RouteNames {
  MAIN_PAGE = '',
  LOGIN_PAGE = 'login',
  FORBIDDEN_PAGE = 'forbidden',
  JOURNAL_PAGE = 'journal',
  KANBAN_PAGE = 'kanban',
  STATISTICS_PAGE = 'statistics',
  DT_IMPORT_PAGE = 'dt-import',
  KDT_IMPORT_PAGE = 'kdt-import',
  EAD_PAGE = 'ead',
  KDT_PAGE = 'kdt',
  DTS_PAGE = 'dts',
  DTS_2_PAGE = 'dts2',
  NOMENCLATURE = 'nomenclature',
  USER = 'user',
  CATALOGS_PAGE = 'catalogs',
  RESET_PASSWORD_PAGE = 'reset-password',
  REGISTER_PAGE = 'register',
  REPORTS_PAGE = 'reports',
  TOOLS = 'tools',
}
export enum OrganizationRouteNames {
  MAIN = 'organization',
  LIST = 'organization/list',
  DETAILS = 'organization/:id',
}

export enum PaymentOrderRouteNames {
  MAIN = 'payment-order',
  LIST = 'payment-order/list',
}
